<template>
  <div></div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>